@charset "utf-8";

///////////////////////////////////////////////////////////
// archive
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.archive__contents_result {
  font-size: 1.2rem;
  padding: 3rem 0;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(14, 22, 375, 768);
  }

  .all,
  .result {
    font-size: inherit;
  }
}