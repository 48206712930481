@charset "utf-8";

///////////////////////////////////////////////////////////
// pageTop
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.pageTop {
  position: absolute;
  top: 15%;
  right: 5.625%;
  @include m.mq-down(md)  {
    top: 6%;
    right: 3.625%;
  }
}
.pageTop__link {
  position: relative;
  display: inline-block;
  width: m.clamp_sizeWidth(90, 100, 992, 1280);
  height: m.clamp_sizeWidth(90, 100, 992, 1280);
  @include m.mq-down(lg) {
    width: 10rem;
    height: 10rem;
  }
  @include m.mq-down(md) {
    width: m.clamp_sizeWidth(64, 128, 375, 768);
    height: m.clamp_sizeWidth(64, 128, 375, 768);
  }

  @include m.hover {
    .pageTop__link_text {
      animation: 6s linear infinite rotation;
      @keyframes rotation{
        0%{ transform: translate(-50%, -50%) rotate(0);}
        100%{ transform: translate(-50%, -50%) rotate(360deg); }
      }
    }
  }
}
.pageTop__link_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pageTop__link_character {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-46%,-55%);
  @include m.mq-down(md) {
    width: m.clamp_sizeWidth(34, 54, 375, 768);
  }
}