@charset "utf-8";

///////////////////////////////////////////////////////////
// defaultCards
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.defaultCards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  @include m.mq-down(md) {
    grid-template-columns: repeat(1, 1fr);
    gap: m.clamp_sizeWidth(30, 60, 375, 768);
  }
}

.defaultCard {
  display: flex;
  flex-direction: column;

  &--campus {
    .defaultCard__image {
      aspect-ratio: 350 / 197;
    }
  }
}

.defaultCard__thumb {
  margin: 0;
}

.defaultCard__image {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 350 / 247;
}

.defaultCard__title {
  font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
  font-weight: 600;
  margin-top: .5em;
  margin-bottom: m.clamp_sizeWidth(15, 20, 768, 1280);
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(14, 24, 375, 768);
    margin-bottom: 0;
  }
} 

.defaultCards__btn {
  margin-top: auto;
  @include m.mq-down(md) {
    margin-top: m.clamp_sizeWidth(15, 30, 375, 768);
  }
}

.defaultCard__link {
  display: block;
  overflow: hidden;

  .defaultCard__image {
    transition: v.$transition_scale;
  }

  @include m.hover {
    .defaultCard__image {
      transform: v.$transform_scale;
    }
  }
}