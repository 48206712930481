@charset "utf-8";

///////////////////////////////////////////////////////////
// newsList
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.newsTabList {
  display: flex;
  border-bottom: 1px solid v.$clr_blue;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  
  &--home {
    margin-top: m.clamp_sizeWidth(24, 30, 768, 1280);
  }

  &::-webkit-scrollbar {
    display:none;
  }
}

.newsTabList__tab {
  font-size: 1.2rem;
  color: #fff;
  text-align: center;
  padding: .66em 1em;
  background-color: v.$clr_gray;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  @include m.linkColor(#fff);
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(12, 22, 375, 768);
    padding: .45em 1em;
  }

  & + & {
    margin-left: 1rem;
  }

  &.is-tab-active {
    background-color: v.$clr_blue;
    pointer-events: none;
  }
}

.newsTabList__contents {
  display: none;

  &.is-tab-active {
    display: block;
  }
}