@charset "utf-8";

///////////////////////////////////////////////////////////
// single
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.breadcrumb span[property="name"] {
  display: inline-block;
  vertical-align: middle;
  max-width: 30rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include m.mq-down(md) {
    max-width: m.clamp_sizeWidth(100, 200, 375, 768);
  }
}

.article {

  h1 {
    position: relative;
    font-size: m.clamp_sizeWidth(28, 32, 768, 1280);
    font-weight: 600;
    line-height: 1.63;
    padding-bottom: 0.3em;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(20, 40, 375, 768);
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      width: 100%;
      height: 2px;
      background-color: v.$clr_blue;
    }
  }
}

.article__head {
  margin-bottom: m.clamp_sizeWidth(40, 60, 768, 1280);
  @include m.mq-down(md) {
    margin-bottom: m.clamp_sizeWidth(40, 80, 375, 768);
  }
}

.article__date {
  margin-top: 2rem;
  @include m.mq-down(md) {
    margin-top: m.clamp_sizeWidth(10, 20, 375, 768);
  }

  time {
    font-size: 1.2rem;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(12, 24, 375, 768);
    }
  }
}

.article__btn {
  margin-top: m.clamp_sizeWidth(68, 88, 768, 1280);
  @include m.mq-down(md) {
    margin-top: m.clamp_sizeWidth(50, 100, 375, 768);
  }

}

//---------------------------------------------------------
// content
//---------------------------------------------------------
.content {
  h2 {
    position: relative;
    font-size: m.clamp_sizeWidth(24, 26, 768, 1280);
    font-weight: 600;
    padding-left: .7em;
    line-height: 1.45;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(18, 36, 375, 768);
      padding-left: .7em;
    }
  
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: .5rem;
      height: 90%;
      background-color: v.$clr_blue;
      border-radius: 2px;
      @include m.mq-down(md) {
        width: m.clamp_sizeWidth(2.5, 5, 375, 768);
      }
    }

    & + * {
      margin-top: 2%;
      @include m.mq-down(md) {
        margin-top: 4%;
      }
    }
  }

  h3 {
    position: relative;
    font-size: m.clamp_sizeWidth(22, 24, 768, 1280);
    font-weight: 600;
    padding-bottom: .2em;
    margin-bottom: 1em;
    line-height: 1.45;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(16, 32, 375, 768);
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: v.$clr_blue;
    }

    & + * {
      margin-top: 2%;
      @include m.mq-down(md) {
        margin-top: 4%;
      }
    }
  }

  h4, h5, h6 {
    font-size: m.clamp_sizeWidth(18, 20, 768, 1280);
    font-weight: 600;
    line-height: 1.4;
    margin: 2em 0 1em;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(14, 28, 375, 768);
    }
  }

  p {
    font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
    line-height: 2.13;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(12, 24, 375, 768);
    }

    & + * {
      margin-top: 2%;
      @include m.mq-down(md) {
        margin-top: 8%;
      }
    }

    & + h2,
    & + h3 {
      margin-top: 4%;
      @include m.mq-down(md) {
        margin-top: 8%;
      }
    }

    &.icon {
      position: relative;
      font-weight: 600;
      padding-left: 1.2em;

      &::before {
        position: absolute;
        top: m.clamp_sizeWidth(10, 11, 768, 1280);
        left: 0;
        content: "";
        display: inline-block;
        width: 1.1rem;
        height: 1.1rem;
        margin-right: 0.5em;
        border-radius: 50%;
        background-color: v.$clr_blue;
        @include m.mq-down(md) {
          top: m.clamp_sizeWidth(8, 15, 375, 768);
          width: m.clamp_sizeWidth(11, 22, 375, 768);
          height: m.clamp_sizeWidth(11, 22, 375, 768);
        }
      }
    }

    &.button > a {
      position: relative;
      display: inline-block;
      font-size: m.clamp_sizeWidth(13, 15, 768, 1280);
      font-weight: 600;
      text-align: center;
      width: m.clamp_sizeWidth(240, 280, 768, 1280);
      max-width: 100%;
      padding: .75em 1em;
      box-sizing: border-box;
      border-radius: 25px;
      border: 2px solid transparent;
      background-color: v.$clr_blue;
      transition: all .3s a.$ease;
      @include m.mq-down(md) {
        font-size: m.clamp_sizeWidth(12, 23, 375, 768);
        width: m.clamp_sizeWidth(195, 390, 375, 768);
        border-radius: m.clamp_sizeWidth(25, 50, 375, 768);
      }
      @include m.linkColor(#fff);
      @include m.hover {
        color: v.$clr_blue;
        border: 2px solid v.$clr_blue;
        background-color: #fff;
      }

      &::after {
        display: none;
      }
    }

    &.button--prev > a {
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 1.3em;
        transform: translateY(-50%);
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 6px solid #fff;
        border-left: 0;
        @include m.mq-down(md) {
          border-top: m.clamp_sizeWidth(5, 10, 375, 768) solid transparent;
          border-bottom: m.clamp_sizeWidth(5, 10, 375, 768) solid transparent;
          border-right: m.clamp_sizeWidth(6, 12, 375, 768) solid #fff;
        }
      }
      
      @include m.hover {
        &::before {
          border-right: 6px solid v.$clr_blue;
        }
      }
    }

    &.button--download > a {
      width: m.clamp_sizeWidth(310, 350, 768, 1280);
      @include m.mq-down(md) {
        width: m.clamp_sizeWidth(280, 600, 375, 768);
      }
  
      &::before {
        display: inline-block;
        content: "\f56d";
        font-weight: 900;
        font-family: "Font Awesome 6 Free";
        color: #fff;
        margin-right: .4em;
      }
  
      @include m.hover {
        &::before {
          color: v.$clr_blue;
        }
      }
    }
  }

  strong {
    font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
    line-height: 2.13;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(12, 24, 375, 768);
    }
  }

  blockquote {
    position: relative;
    margin: 4% 0;
    padding: m.clamp_sizeWidth(28, 44, 768, 1280) m.clamp_sizeWidth(56, 76, 768, 1280);
    background-color: #f8f8f8;
    @include m.mq-down(md) {
      padding: m.clamp_sizeWidth(40, 80, 375, 768);
    }

    &::before,
    &::after {
      content: "“";
      position: absolute;
      font-size: m.clamp_size(85, 105);
      font-size:  m.clamp_sizeWidth(78, 90, 768, 1280);
      font-family: v.$fnt_gothic;
      color: #b4b4b4;
      line-height: 1;
      @include m.mq-down(md) {
        font-size: m.clamp_sizeWidth(68, 136, 375, 768);
      }
    }

    &::before {
      top: 8.5%;
      left: 2.5%;
      @include m.mq-down(md) {
        top: 2%;
      }
    }

    &::after {
      bottom: 8.5%;
      right: 2.5%;
      transform: rotate(180deg);
      @include m.mq-down(md) {
        bottom: 2%;
      }
    }

    cite {
      display: block;
      margin-top: 1em;
      @include m.mq-down(md) {
        font-size: m.clamp_sizeWidth(10, 20, 375, 768);
      }
    }
  }

  figure {
    & + * {
      margin-top: 2%;
      @include m.mq-down(md) {
        margin-top: 8%;
      }
    }

    figcaption {
      font-size: m.clamp_sizeWidth(12, 14, 768, 1280);
      margin-top: .8em;
      @include m.mq-down(md) {
        font-size: m.clamp_sizeWidth(10, 20, 375, 768);
      }
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      aspect-ratio: 730 / 516;
      max-width: 65rem;
    }
  }

  ol {
    list-style-type: none;
    counter-reset: item;

    & + * {
      margin-top: 2%;
      @include m.mq-down(md) {
        margin-top: 8%;
      }
    }

    & + h2,
    & + h3 {
      margin-top: 4%;
      @include m.mq-down(md) {
        margin-top: 8%;
      }
    }

    li {
      position: relative;
      font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
      padding-left: 1.2em;
      @include m.mq-down(md) {
        font-size: m.clamp_sizeWidth(12, 24, 375, 768);
      }

      & + li {
        margin-top: 1rem;
      }
      
      &::before {
        position: absolute;
        top: m.clamp_sizeWidth(10, 12, 768, 1280);
        left: 0;
        transform: translateY(-50%);
        counter-increment: item;
        content: counter(item)'.';
        font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
        color: v.$clr_blue;
        padding-right: .5em;
        @include m.mq-down(md) {
          top: m.clamp_sizeWidth(9, 19, 375, 768);
          font-size: m.clamp_sizeWidth(12, 24, 375, 768);
        }
      }
    }
  }
  
  ul {
    list-style: none;

    & + * {
      margin-top: 2%;
      @include m.mq-down(md) {
        margin-top: 8%;
      }
    }

    & + h2,
    & + h3 {
      margin-top: 4%;
      @include m.mq-down(md) {
        margin-top: 8%;
      }
    }
    
    li {
      position: relative;
      font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
      padding-left: 1.2em;
      @include m.mq-down(md) {
        font-size: m.clamp_sizeWidth(12, 24, 375, 768);
      }

      & + li {
        margin-top: 1rem;
      }

      &::before {
        position: absolute;
        top: m.clamp_sizeWidth(6.5, 7.5, 768, 1280);
        left: 0;
        content: "";
        display: inline-block;
        width: 1.1rem;
        height: 1.1rem;
        margin-right: 0.5em;
        border-radius: 50%;
        background-color: v.$clr_blue;
        @include m.mq-down(md) {
          top: m.clamp_sizeWidth(4.5, 9.5, 375, 768);
          width: m.clamp_sizeWidth(11, 22, 375, 768);
          height: m.clamp_sizeWidth(11, 22, 375, 768);
        }
      }
    }
  }

  a {
    position: relative;
    display: inline-block;
    font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
    text-decoration: none;
    word-wrap: break-word;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(12, 24, 375, 768);
    }

    &::after {
      content: "";
      position: absolute;
      bottom: .2em;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background-color: v.$clr_blue;
      transition: opacity .2s a.$easeOut;
    }

    @include m.hover {
      &::after {
        opacity: 0;
      }
    }

    &.wp-block-button__link {
      position: relative;
      display: inline-block;
      font-size: m.clamp_sizeWidth(13, 15, 768, 1280);
      font-weight: 600;
      text-align: center;
      max-width: 100%;
      padding: .75em 2em;
      box-sizing: border-box;
      border-radius: 25px;
      border: 2px solid transparent;
      background-color: v.$clr_blue;
      transition: all .3s a.$ease;
      text-decoration: none;
      @include m.mq-down(md) {
        font-size: m.clamp_sizeWidth(12, 23, 375, 768);
        border-radius: m.clamp_sizeWidth(25, 50, 375, 768);
      }
      @include m.linkColor(#fff);
      @include m.hover {
        color: v.$clr_blue;
        border: 2px solid v.$clr_blue;
        background-color: #fff;
      }
      &::after {
        display: none;
      }
    }
  }

  .wp-block-table {
    @include m.mq-down(lg) {
      overflow-x: auto;
    }

    & + h2,
    & + h3 {
      margin-top: 4%;
      @include m.mq-down(md) {
        margin-top: 8%;
      }
    }

    table {
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;
      @include m.mq-down(lg) {
        width: 92rem;
      }
      @include m.mq-down(md) {
        width: m.clamp_sizeWidth(550, 1100, 375, 768);
      }

      thead {
        border-bottom: none;
      }
      
      tr {
        background-color: transparent !important;
      }
      
      th,
      td {
        font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
        padding: 1.2em 2em;
        border: 1px solid #f2f2f2;
        @include m.mq-down(md) {
          font-size: m.clamp_sizeWidth(15, 24, 375, 768); 
          padding: 1.2em 1em;
        }
        
        small {
          display: block;
          line-height: 1.6;
          margin-top: .5rem;
          @include m.mq-down(md) {
            font-size: m.clamp_sizeWidth(11, 16, 375, 768);
            margin-top: 0.3125em;
          }
        }
      }

      a {
        white-space: nowrap;
      }
    }
  }
}