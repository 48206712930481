@charset "utf-8";

///////////////////////////////////////////////////////////
// heading
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.lv2Heading {
  position: relative;
  font-size: m.clamp_sizeWidth(24, 26, 768, 1280);
  font-weight: 600;
  padding-left: .7em;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(18, 36, 375, 768);
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: .5rem;
    height: 90%;
    background-color: v.$clr_blue;
    border-radius: 2px;
    @include m.mq-down(md) {
      width: m.clamp_sizeWidth(2.5, 5, 375, 768);
    }
  }
}

.lv3Heading {
  position: relative;
  font-size: m.clamp_sizeWidth(22, 24, 768, 1280);
  font-weight: 600;
  padding-bottom: .2em;
  line-height: 1.45;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(16, 32, 375, 768);
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: v.$clr_blue;
  }
}