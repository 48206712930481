@charset "utf-8";

///////////////////////////////////////////////////////////
// international
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.international__info_content {
  & + & {
    margin-top: 5rem;
    @include m.mq-down(md) {
      margin-top: m.clamp_sizeWidth(50, 100, 375, 768);
    }
  }
}