@charset "utf-8";

///////////////////////////////////////////////////////////
// breadcrumb
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.breadcrumb {
  text-align: right;

  span[property="itemListElement"] {
    position: relative;
    display: inline-block;
  
    &:not(:first-of-type) {
      margin-left: 2.7rem;
      @include m.mq-down(md) {
        margin-left: m.clamp_sizeWidth(15, 30, 375, 768);
      }
  
      &::before {
        content: "\f0da";
        font-size: 1.2em;
        font-weight: 900;
        font-family: "Font Awesome 6 Free";
        color: v.$clr_blue;
        position: absolute;
        top: 50%;
        left: -1.6rem;
        transform: translateY(-45%);
        @include m.mq-down(md) {
          font-size: m.clamp_sizeWidth(12, 24, 375, 768);
          left: -.9em;
        }
      }
    }
  }
  
  span[property="name"] {
    position: relative;
    display: inline-block;
    padding: .2rem 0;
    font-size: 1.2rem;
    font-weight: 600;
    color: v.$clr_black;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(10, 22, 375, 768);
    }
  
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: .2em;
      display: block;
      width: 100%;
      height: 1px;
      background-color: v.$clr_black;
      transition: opacity .2s a.$easeOut;
      transform: scaleX(0);
      transform-origin: 100% 0;
      transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
  
  a[property="item"] {
    @include m.hover {
      span[property="name"] {
        &::after {
          transform: scaleX(1);
          transform-origin: 0 0;
        }
      }
    }
  }
}