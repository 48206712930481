@charset "utf-8";

///////////////////////////////////////////////////////////
// contentUnit
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.contentUnit {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: max-content max-content 1fr auto;
  gap: 0 3rem;
  @include m.mq-down(md) {
    display: block;
  }

  & + & {
    margin-top: m.clamp_sizeWidth(40, 50, 768, 1280);
    @include m.mq-down(md) {
      margin-top: m.clamp_sizeWidth(40, 80, 375, 768);
    }
  }

  &--reverse,
  &:nth-child(2n) {
    .contentUnit__title {
      grid-area: 1 / 2 / 2 / 3;
    }

    .contentUnit__thumb {
      grid-area: 1 / 1 / 4 / 2;
    }

    .contentUnit__text {
      grid-area: 2 / 2 / 3 / 3;
    }

    .contentUnit__btn {
      grid-area: 3 / 2 / 4 / 3;
    }
  }
}

.contentUnit__title {
  grid-area: 1 / 1 / 2 / 2;
}

.contentUnit__thumb {
  grid-area: 1 / 2 / 4 / 3;
  @include m.mq-down(md) {
    margin-top: m.clamp_sizeWidth(20, 40, 375, 768);
  }
}

.contentUnit__image {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 540 / 385;
}

.contentUnit__text {
  font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
  line-height: 2.13;
  margin-top: 1em;
  grid-area: 2 / 1 / 3 / 2;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(12, 24, 375, 768);
  }
}

.contentUnit__btn {
  grid-area: 3 / 1 / 4 / 2;
  margin-top: m.clamp_sizeWidth(20, 30, 768, 1280);
  @include m.mq-down(md) {
    text-align: center;
    margin-top: m.clamp_sizeWidth(15, 30, 375, 768);
  }
}