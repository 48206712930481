@charset "utf-8";

///////////////////////////////////////////////////////////
// footer
///////////////////////////////////////////////////////////

@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.footer {
  position: relative;
  background-color: v.$clr_dred;
}

.footer__inner {
  display: flex;
  padding: m.clamp_sizeWidth(40, 50, 768, 1280) 0;
  @include m.mq-down(lg) {
    flex-direction: column;
  }
  @include m.mq-down(md) {
    padding: m.clamp_sizeWidth(40, 50, 375, 768) 0;
  }
}

.footer__logo {
  width: 20rem;
  text-align: center;
  @include m.mq-down(lg) {
    margin: 0 auto;
  }
  @include m.mq-down(md) {
    width: m.clamp_sizeWidth(178, 335, 375, 768);
  }
}

.footer__logo_link {
  display: inline-block;
  width: inherit;

  img {
    width: 100%;
  }
}

.footer__university_link {
  display: inline-block;
  margin-top: 2.5rem;
  @include m.mq-down(md) {
    margin-top: m.clamp_sizeWidth(15, 30, 375, 768);
  }

  img {
    @include m.mq-down(md) {
      width: m.clamp_sizeWidth(103, 206, 375, 768);
    }
  }
}

.footer__university_link_wrap {
  display: flex;
  align-items: center;
  
  &::after {
    content: "\f08e";
    font-size: 1.2rem;
    font-family: "Font Awesome 6 Free";
    color: #fff;
    margin-left: 2rem;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(10, 18, 375, 768);
      margin-left: m.clamp_sizeWidth(10, 20, 375, 768);
    }
  }
}

.footer__nav {
  width: calc(100% - 20rem);
  margin-left: m.clamp_sizeWidth(80, 156, 992, 1280);
  @include m.mq-down(lg) {
    width: 100%;
    margin-top: 3rem;
    margin-left: 0;
  }
  @include m.mq-down(md) {
    margin-top: m.clamp_sizeWidth(26, 36, 375, 768);
  }
}

.footer__navList {
  display: flex;
  @include m.mq-down(lg) {
    justify-content: center;
  }
}

.footer__navList_column {
  &:nth-child(2) {
    margin-left: 9.4%;
    @include m.mq-down(md) {
      margin-left: m.clamp_sizeWidth(8, 30, 375, 768);
    }
  }
  &:nth-child(3) {
    margin-left: m.clamp_sizeWidth(65, 94, 991.98, 1280);
    @include m.mq-down(md) {
      margin-left: m.clamp_sizeWidth(8, 30, 375, 768);
    }
  }
}

.footer__navList_item {
  & + & {
    margin-top: 1rem;
  }
}

.footer__navList_itemLink {
  position: relative;
  display: inline-block;
  padding: .16em 0;
  @include m.linkColor(#fff);

  &.outlook {
    padding-left: 0;
    padding-right: 1.4em;
    @include m.mq-down(md) {
      padding-right: m.clamp_sizeWidth(14, 24, 375, 768);
    }

    &::before {
      display: none;
    }
    
    .outlookIcon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: inline-block;

      &::before {
        content: "\f08e";
        font-size: 1.2rem;
        font-family: "Font Awesome 6 Free";
        @include m.mq-down(md) {
          font-size: m.clamp_sizeWidth(10, 18, 375, 768);
        }
      }
    }
  }
}

.footer__navLink_txt {
  position: relative;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 600;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(10, 20, 375, 768);
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: opacity .2s a.$easeOut;
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  @include m.hover {
    &::after {
      transform: scaleX(1);
      transform-origin: 0 0;
    }
  }
}

.footer__copyright {
  padding: 1.7rem 0;
  background-color: #fff;
  @include m.mq-down(md) {
    padding: m.clamp_sizeWidth(12, 16, 375, 768) 0;
  }
}

.footer__copyright_text {
  font-size: 1.2rem;
  text-align: center;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(10, 12, 375, 768);
  }
}