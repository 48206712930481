@charset "utf-8";

///////////////////////////////////////////////////////////
// section
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.section {
  margin-top: m.clamp_sizeWidth(80, 100, 768, 1280);
  @include m.mq-down(md) {
    margin-top: m.clamp_sizeWidth(50, 100, 375, 768);
  }

  &--home {
    margin-top: m.clamp_sizeWidth(30, 50, 768, 1280);
    @include m.mq-down(md) {
      margin-top: m.clamp_sizeWidth(30, 50, 375, 768);
    }
  }

  &--bgc-skyblue {
    background-color: v.$clr_lskyblue;
    padding: m.clamp_sizeWidth(80, 100, 768, 1280) 0;
    @include m.mq-down(md) {
      padding: m.clamp_sizeWidth(50, 100, 375, 768) 0;
    }
  }
}