@charset "utf-8";

///////////////////////////////////////////////////////////
// campus
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.campus__entrance_text {
  p {
    font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
    line-height: 2.13;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(12, 24, 375, 768);
    }
  }

  a {
    @include m.hover {
      text-decoration: underline;
    }
  }
}

.campus__map {
  & + & {
    margin-top: 3rem;
    @include m.mq-down(md) {
      margin-top: m.clamp_sizeWidth(20, 40, 375, 768);
    }
  }
  &:last-of-type {
    .campus__map_body {
      padding-bottom: 0;
    }
  }
}

.campus__map_head {
  position: relative;
  font-size: m.clamp_sizeWidth(16, 18, 768, 1280);
  font-weight: 600;
  color: #fff;
  padding: .85em 1.1em .85em 1.6em;
  border-radius: 5px;
  background-color: v.$clr_blue;
  cursor: pointer;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(16, 32, 375, 768);
    padding: .5em .9375em .5em .9375em;
  }

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    right: 1.1em;
    transform: translateY(-50%);
    content: '';
    display: block;
    width: 1.8rem;
    height: 3.5px;
    background: #fff;
    border-radius: 5px;
    @include m.mq-down(md) {
      width: m.clamp_sizeWidth(16, 32, 375, 768);
    }
  }

  &::after {
    transform: translateY(-50%) rotate(90deg);
    transition: all .4s a.$easeOut;
  }

  &.is-active {
    &::after {
      transform: translateY(-50%) rotate(0);
    }
  }
}

.campus__map_body {
  display: none;
  padding-top: 3rem;
  padding-bottom: m.clamp_sizeWidth(80, 100, 768, 1280);
  transition: opacity .4s a.$easeOut;
  transition-delay: .2s;
  opacity: 0;
  @include m.mq-down(md) {
    padding-top: m.clamp_sizeWidth(15, 30, 375, 768);
    padding-bottom: m.clamp_sizeWidth(50, 100, 375, 768);
  }

  &.is-active {
    display: block;
    opacity: 1;
  }
}

.campus__map_content {
  margin-top: m.clamp_sizeWidth(40, 50, 768, 1280);
  @include m.mq-down(md) {
    margin: m.clamp_sizeWidth(40, 60, 375, 768) 0  m.clamp_sizeWidth(30, 60, 375, 768);
  }
}

.campus__map_zone {
  & + & {
    margin-top: m.clamp_sizeWidth(80, 100, 768, 1280);
    @include m.mq-down(md) {
      margin-top: m.clamp_sizeWidth(40, 80, 375, 768);
    }
  }
}

.campus__map_title {
  position: relative;
  font-size: m.clamp_sizeWidth(24, 26, 768, 1280);
  font-weight: 600;
  margin: 2em 0 1em;
  padding-left: .7em;
  line-height: 1.45;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(16, 32, 375, 768);
    padding-left: .7em;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: .5rem;
    height: 90%;
    background-color: v.$clr_blue;
    border-radius: 2px;
    @include m.mq-down(md) {
      width: m.clamp_sizeWidth(2.5, 5, 375, 768);
    }
  }
}

.campus__map_image {
  position: relative;
}

/* pin */
.pin {
  position: absolute;
  display: flex;
  justify-content: center;
  // width: m.clamp_sizeWidth(34, 40, 768, 1280);
  width: 4rem;
  @include m.mq-down(sm) {
    width: 2.3rem;
  }
  // @include m.mq-down(md) {
  //   width: m.clamp_sizeWidth(23, 46, 375, 768);
  // }
  @include m.hover {
    z-index: 5;
  }

  // &--01 {
  //   top: 11%;
  //   left: 60%;
  //   @include m.mq-down(xl) {
  //     top: 12%;
  //     left: 41%;
  //   }
  // }

  // &--02 {
  //   top: 39%;
  //   left: 69%;
  //   @include m.mq-down(xl) {
  //     left: 52%;
  //     top: 33%;
  //   }
  // }

  // &--03 {
  //   left: 36%;
  //   bottom: 17%;
  //   @include m.mq-down(xl) {
  //     bottom: 20%;
  //     left: 15%;
  //   }
  // }

  // &--04 {
  //   top: 33%;
  //   left: 30%;
  //   @include m.mq-down(xl) {
  //     top: 36%;
  //     left: 26%;
  //   }
  // }

  // &--05 {
  //   top: 35%;
  //   left: 40%;
  //   @include m.mq-down(xl) {
  //     top: 37%;
  //     left: 38%;
  //   }
  // }

  // &--06 {
  //   top: 43%;
  //   left: 37%;
  //   @include m.mq-down(xl) {
  //     top: 40%;
  //     left: 34%;
  //   }
  // }

  // &--07 {
  //   top: 38%;
  //   left: 28%;
  //   @include m.mq-down(xl) {
  //     top: 41%;
  //     left: 22%;
  //   }
  // }

  // &--08 {
  //   left: 42%;
  //   top: 19%;
  //   @include m.mq-down(xl) {
  //     top: 16%;
  //     left: 30%;
  //   }
  // }

  // &--09 {
  //   left: 50%;
  //   top: 29%;
  //   @include m.mq-down(xl) {
  //     top: 25%;
  //     left: 41.5%;
  //   }
  //   @include m.mq-down(md) {
  //     left: 41.5%;
  //     top: 17%;
  //   }
  // }

  // &--10 {
  //   left: 49%;
  //   top: 36%;
  //   @include m.mq-down(xl) {
  //     top: 31%;
  //     left: 42%;
  //   }
  // }

  // &--11 {
  //   bottom: 38%;
  //   left: 51%;
  //   @include m.mq-down(xl) {
  //     bottom: 33%;
  //     left: 45%;
  //   }
  // }

  // &--12 {
  //   left: 47.5%;
  //   top: 26%;
  //   @include m.mq-down(xl) {
  //     top: 25%;
  //     left: 38.5%;
  //   }
  // }

  // &--13 {
  //   left: 43.5%;
  //   top: 36%;
  //   @include m.mq-down(xl) {
  //     top: 31%;
  //     left: 30.5%;
  //   }
  // }

  // &--14 {
  //   left: 41%;
  //   top: 45%;
  //   @include m.mq-down(xl) {
  //     top: 50%;
  //     left: 30.5%;
  //   }
  // }

  // &--15 {
  //   bottom: 18%;
  //   left: 34.5%;
  //   @include m.mq-down(xl) {
  //     bottom: 21%;
  //     left: 25.5%;
  //   }
  // }

  // &--16 {
  //   top: 35%;
  //   left: 51%;
  //   @include m.mq-down(xl) {
  //     left: 41%;
  //     top: 37%;
  //   }
  //   @include m.mq-down(md) {
  //     left: 39%;
  //     top: 29%;
  //   }
  // }

  // &--17 {
  //   bottom: 28%;
  //   left: 53.5%;
  //   @include m.mq-down(xl) {
  //     bottom: 29%;
  //     left: 42.5%;
  //   }
  //   @include m.mq-down(md) {
  //     left: 41.5%;
  //   }
  // }

  // &--18 {
  //   bottom: 16%;
  //   left: 49.3%;
  //   @include m.mq-down(xl) {
  //     bottom: 16%;
  //     left: 38%;
  //   }
  //   @include m.mq-down(md) {
  //     left: 36.9%;
  //   }
  // }

  // &--19 {
  //   left: 43%;
  //   top: 23%;
  //   @include m.mq-down(xl) {
  //     left: 30.5%;
  //     top: 24%;
  //   }
  //   @include m.mq-down(md) {
  //     left: 29%;
  //     top: 18%;
  //   }
  // }

  // &--20 {
  //   bottom: 23%;
  //   left: 24%;
  //   @include m.mq-down(xl) {
  //     bottom: 23%;
  //     left: 7%;
  //   }
  //   @include m.mq-down(md) {
  //     left: 6%;
  //   }
  // }

  // &--21 {
  //   left: 41%;
  //   top: 13%;
  //   @include m.mq-down(xl) {
  //     top: 13.5%;
  //     left: 50%;
  //   }
  //   @include m.mq-down(md) {
  //     left: 49%;
  //     top: 7%;
  //   }
  // }

  // &--22 {
  //   bottom: 40%;
  //   left: 34%;
  //   @include m.mq-down(xl) {
  //     left: 42%;
  //   }
  //   @include m.mq-down(md) {
  //     bottom: 38%;
  //     left: 41%;
  //   }
  // }

  // &--23 {
  //   bottom: 26%;
  //   left: 39%;
  //   @include m.mq-down(xl) {
  //     bottom: 28%;
  //     left: 48%;
  //   }
  //   @include m.mq-down(md) {
  //     bottom: 25%;
  //     left: 47%;
  //   }
  // }

  // &--24 {
  //   left: 48%;
  //   top: 40%;
  //   @include m.mq-down(xl) {
  //     top: 41%;
  //     left: 58%;
  //   }
  //   @include m.mq-down(md) {
  //     left: 56%;
  //     top: 33%;
  //   }
  // }

  // &--25 {
  //   left: 53%;
  //   top: 29%;
  //   @include m.mq-down(xl) {
  //     top: 30%;
  //     left: 65%;
  //   }
  //   @include m.mq-down(md) {
  //     left: 64%;
  //     top: 23%;
  //   }
  // }

  // &--26 {
  //   bottom: 24%;
  //   left: 38.2%;
  //   @include m.mq-down(xl) {
  //     bottom: 25%;
  //     left: 38%;
  //   }
  //   @include m.mq-down(md) {
  //     bottom: 26%;
  //     left: 37%;
  //   }
  // }

  // &--27 {
  //   left: 34%;
  //   top: 27%;
  //   @include m.mq-down(xl) {
  //     left: 32.5%;
  //     top: 28.5%;
  //   }
  //   @include m.mq-down(md) {
  //     left: 32%;
  //     top: 21%;
  //   }
  // }

  // &--28 {
  //   left: 32%;
  //   top: 31%;
  //   @include m.mq-down(xl) {
  //     left: 30%;
  //     top: 31%;
  //   }
  //   @include m.mq-down(md) {
  //     left: 28.5%;
  //     top: 25%;
  //   }
  // }

  // &--29 {
  //   left: 40.5%;
  //   top: 39%;
  //   @include m.mq-down(xl) {
  //     left: 41%;
  //     top: 38%;
  //   }
  //   @include m.mq-down(md) {
  //     left: 40%;
  //     top: 33%;
  //   }
  // }

  // &--30 {
  //   bottom: 20%;
  //   left: 35.5%;
  //   @include m.mq-down(xl) {
  //     bottom: 20%;
  //     left: 35%;
  //   }
  //   @include m.mq-down(md) {
  //     bottom: 20%;
  //     left: 34%;
  //   }
  // }
}

.pin__icon {
  width: 100%;
}

.pin__num {
  position: absolute;
  top: 0.5rem;
  color: white;
  // font-size: m.clamp_sizeWidth(14, 16, 375, 768);
  font-size: 1.6rem;
  font-weight: 600;
  @include m.mq-down(sm) {
    font-size: 1rem;
  }
  // @include m.mq-down(md) {
  //   font-size: m.clamp_sizeWidth(10, 20, 375, 768);
  // }
}

/* 各場所モーダル */
.campus__places {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  @include m.mq-down(lg) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include m.mq-down(md) {
    grid-template-columns: repeat(1, 1fr);
    gap: m.clamp_sizeWidth(15, 30, 375, 768);
    margin-top: m.clamp_sizeWidth(20, 40, 375, 768);
  }
}

.campus__place {
  &--facility {
    .campus__place_head {
      border: solid 3px #6494D9;
      
      &::after {
        color: #6494D9;
      }

      .num {
        background-color: #6494D9;
      }
    }

    .campus__place_body {
      border-right: solid 3px #6494D9;
      border-bottom: solid 3px #6494D9;
      border-left: solid 3px #6494D9;
    }
  }
}

.campus__place_head {
  position: relative;
  display: grid;
  place-items: center;
  height: 4rem;
  padding: 0.3rem 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  overflow: hidden;
  border: solid 3px #E0818A;
  border-radius: 2.4rem;
  background-color: #fff;
  cursor: pointer;
  transition: border-radius .2s a.$easeOut;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(13, 26, 375, 768);
    height: m.clamp_sizeWidth(40, 65, 375, 768);
    border-radius: m.clamp_sizeWidth(24, 48, 375, 768);
  }
  
  &::after {
    content: "\f078";
    font-family: "Font Awesome 6 Free";
    color: #E0818A;
    right: 1.5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: transform .2s a.$easeOut;
  }

  &.is-active {
    border-radius: 2.4rem 2.4rem 0 0;

    &::after {
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  .num {
    position: absolute;
    top: 50%;
    left: -2px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    font-size: 1.4rem;
    font-weight: 600;
    color: #fff;
    line-height: 1;
    background-color: #E0818A;
    border-radius: 0 5rem 5rem 0;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(13, 26, 375, 768);
      width: m.clamp_sizeWidth(40, 65, 375, 768);
      height: m.clamp_sizeWidth(40, 65, 375, 768);
    }
  }
}

.campus__place_body {
  display: none;
  padding: 1rem;
  background-color: #fff;
  border-right: solid 3px #E0818A;
  border-bottom: solid 3px #E0818A;
  border-left: solid 3px #E0818A;
  border-radius: 0 0 2rem 2rem;
  transition: opacity .2s a.$easeOut;
  opacity: 0;

  &.is-active {
    opacity: 1;
  }
}

.campus__place_image {
  width: 100%;
}

.campus__place_info {
  padding-top: 0.4rem;
  text-align: right;
}

.campus__place_link {
  display: inline-block;
}

.campus__place_link_wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.campus__place_link_text {
  font-size: 1.4rem;
  font-weight: 600;
  color: v.$clr_black;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(14, 28, 375, 768);
  }
}

.campus__place_link_icon {
  width: 2.5rem;
  margin-left: 1rem;
  @include m.mq-down(md) {
    width: m.clamp_sizeWidth(20, 30, 375, 768);
  }
}