@charset "utf-8";

///////////////////////////////////////////////////////////
// pager
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.wp-pagenavi {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: m.clamp_sizeWidth(30, 50, 768, 1280);
  @include m.mq-down(md) {
    margin-top: m.clamp_sizeWidth(30, 50, 375, 768);
  }

  a,
  span {
    padding: 0 !important;
  }

  .page,
  .current,
  .previouspostslink,
  .nextpostslink,
  .extend {
    margin: 0 .5rem;
  }
  
  .page,
  .current,
  .previouspostslink,
  .nextpostslink {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-family: v.$fnt_en;
    width: 3rem;
    height: 3rem;
    color: #fff;
    border-radius: 5px;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(12, 23, 375, 768);
      width: m.clamp_sizeWidth(24, 48, 375, 768);
      height: m.clamp_sizeWidth(24, 48, 375, 768);
    }
  }

  .page,
  .previouspostslink,
  .nextpostslink {
    color: v.$clr_black;
    position: relative;
    border: 2px solid transparent;
    background-color: #ebebeb;
    transition: all .3s a.$ease;

    @include m.hover {
      color: v.$clr_blue;
      border: 2px solid v.$clr_blue !important;
      background-color: #fff;
    }
  }

  span.current {
    color: #fff;
    background-color: v.$clr_blue;
    border: 2px solid v.$clr_blue !important;
    pointer-events: none;
    box-sizing: content-box;
  }

  .previouspostslink,
  .nextpostslink {
    &::after {
      display: inline-block;
      vertical-align: middle;
      transition: color .3s a.$ease;
    }
    @include m.hover {
      &::after {
        color: v.$clr_blue;
      }
    }
  }

  .previouspostslink {    
    &::after {
      content: "\f0d9";
      font-size: 1.2em;
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
      margin-left: .1rem;
    }
  }

  .nextpostslink {
    &::after {
      content: "\f0da";
      font-size: 1.2em;
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
      margin-right: .1rem;
    }
  }

  .first,
  .last {
    display: none;
  }

  // .extend {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   font-weight: 600;
  //   width: m.clamp_size(15, 17);
  //   height: m.clamp_size(15, 17);
  //   border-radius: 50%;
  //   border: none;

  //   &:last-of-type {
  //     display: none;
  //   }
  // }
}