@charset "utf-8";

///////////////////////////////////////////////////////////
// study abroad
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.abroad__guide_content {
  & + & {
    margin-top: m.clamp_sizeWidth(80, 100, 768, 1280);
    @include m.mq-down(md) {
      margin-top: m.clamp_sizeWidth(50, 100, 375, 768);
    }
  }
}

// ---------------------------------------------------------
// 留学までのSTEP
// ---------------------------------------------------------
.abroad__steps {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.4rem;
  margin-top: 3rem;
  @include m.mq-down(lg) {
    gap: m.clamp_sizeWidth(30, 50, 375, 768);
    grid-template-columns: repeat(1, 1fr);
  }
}

.abroad__step {
  position: relative;
  border-radius: 10px;
  padding: 3rem 7.8%;
  @include m.mq-down(lg) {
    min-height: 18.3rem;
  }
  @include m.mq-down(md) {
    min-height: m.clamp_sizeWidth(190, 316, 375, 768);
  }

  & + & {
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -1.7rem;
      transform: translateY(-50%);
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-top: .9rem solid transparent;
      border-bottom: .9rem solid transparent;
      border-left: .9rem solid v.$clr_blue;
      border-right: 0;
      @include m.mq-down(lg) {
        top: -4rem;
        left: 50%;
        border-top: 1.4rem solid transparent;
        border-bottom: 1.4rem solid transparent;
        border-left: 1.4rem solid v.$clr_blue;
        transform: translateX(-50%) rotate(90deg);
      }
      @include m.mq-down(md) {
        top: -15%;
        border-top: m.clamp_sizeWidth(10, 20, 375, 768) solid transparent;
        border-bottom: m.clamp_sizeWidth(10, 20, 375, 768) solid transparent;
        border-left: m.clamp_sizeWidth(12, 24, 375, 768) solid v.$clr_blue;
      }
    }
  }

  &:nth-child(1) {
    border: 2px solid v.$clr_pink;
  }

  &:nth-child(2) {
    border: 2px solid v.$clr_skyblue;
  }

  &:nth-child(3) {
    border: 2px solid v.$clr_yellow;
  }

  &:nth-child(4) {
    display: grid;
    place-items: center;
    border: 2px solid v.$clr_blue;
  }
}

.abroad__unit {
  display: flex;
  flex-direction: column;
}

.abroad__unit_title {
  text-align: center;

  .num {
    display: block;
    font-size: m.clamp_sizeWidth(16, 18, 768, 1280);
    font-family: v.$fnt_en;
    letter-spacing: .04em;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(16, 32, 375, 768);
    }

    span {
      display: inline-block;
      font-size: m.clamp_sizeWidth(26, 28, 768, 1280);
      margin-left: .15em;
      letter-spacing: .04em;
      @include m.mq-down(md) {
        font-size: m.clamp_sizeWidth(26, 52, 375, 768);
      }
    }
  }

  .title {
    display: block;
    font-size: m.clamp_sizeWidth(15, 18, 992, 1280);
    font-weight: 600;
    margin-top: .4em;
    @include m.mq-down(lg) {
      font-size: m.clamp_sizeWidth(16, 18, 768, 992);
    }
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(16, 32, 375, 768);
    }
  }
}

.abroad__unit_text {
  font-size: m.clamp_sizeWidth(13, 15, 992, 1280);
  line-height: 1.67;
  margin-top: 1em;
  @include m.mq-down(lg) {
    font-size: m.clamp_sizeWidth(14, 15, 768, 992);
    text-align: center;
  }
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(12, 24, 375, 768);
  }
}

.abroad__step_text {
  .highlight {
    font-size: m.clamp_sizeWidth(15, 18, 992, 1280);
    font-weight: 600;
    color: v.$clr_blue;
    padding-bottom: 0.2em;
    background: linear-gradient(to right, #ffeaa5 50%, transparent 50%) 100% bottom/200% 40% no-repeat;
    background-position: 0% bottom;
    @include m.mq-down(lg) {
      font-size: m.clamp_sizeWidth(16, 18, 768, 992);
    }
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(16, 32, 375, 768);
    }
  }
}

// ---------------------------------------------------------
// スケジュール
// ---------------------------------------------------------
.abroad__schedule {
  margin-top: 3rem;
}

.abroad__schedule_text {
  font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
  text-align: center;
  margin-top: 5rem;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(12, 24, 375, 768);
    margin-top: m.clamp_sizeWidth(30, 50, 375, 768);
  }
}

.abroad__schedule_btn {
  margin-top: 3rem;
  @include m.mq-down(md) {
    margin-top: m.clamp_sizeWidth(20, 30, 375, 768);
  }
}

// ---------------------------------------------------------
// 留学体験記
// ---------------------------------------------------------
.abroad__experience {
  background-color: v.$clr_lskyblue;
  padding: m.clamp_sizeWidth(80, 100, 768, 1280) 0;
  margin-top: m.clamp_sizeWidth(80, 100, 768, 1280);
  @include m.mq-down(md) {
    margin-top: m.clamp_sizeWidth(50, 100, 375, 768);
    padding: m.clamp_sizeWidth(50, 100, 375, 768) 0;
  }
}

.abroad__experience_list {
  margin-top: 3rem;
  display: grid;
  gap: 6rem 10rem;
  grid-template-columns: repeat(2, 1fr);
  @include m.mq-down(md) {
    grid-template-columns: repeat(1, 1fr);
    gap: 6rem;
    margin-top: m.clamp_sizeWidth(30, 60, 375, 768);
  }
  @include m.mq-down(sm) {
    gap: 4rem;
  }
}

.abroad__experience_thumb {
  width: min(58%, 20.5rem);
  margin: 0 auto;
  @include m.mq-down(md) {

  }
  
  img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 50%;
  }
}

.abroad__experience_name {
  font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
  text-align: center;
  margin-top: 1rem;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(12, 24, 375, 768);
  }

  span {
    display: block;
    font-size: inherit;
  }

  .name {
    font-weight: bold;
  }

  .faculty {
    margin-top: .4em;
  }
}

.abroad__experience_data {
  position: relative;
  display: grid;
  place-items: center;
  padding: 2rem 0;
  margin-top: 2rem;
  border-radius: 10px;
  border: 2px solid v.$clr_blue;
  background-color: #fff;

  &::before {
    content: "";
    border-right: 20px solid transparent;
    border-bottom: 20px solid v.$clr_blue;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%) rotate(135deg);
  }

  &::after {
    content: "";
    border-right: 16px solid transparent;
    border-bottom: 16px solid white;
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%)  rotate(135deg);
  }
}

.abroad__experience_inner {
  display: inline-block;
}

.abroad__experience_data_wrap {
  display: flex;

  & + & {
    margin-top: .8rem;
  }

  .title,
  .data {
    font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(12, 24, 375, 768);
    }
  }

  .title {
    font-weight: 600;
  }
}

.abroad__experience_btn {
  text-align: center;
  margin-top: m.clamp_sizeWidth(20, 30, 768, 1280);
  @include m.mq-down(md) {
    margin-top: m.clamp_sizeWidth(15, 30, 375, 768);
  }
}