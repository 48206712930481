@charset "utf-8";

///////////////////////////////////////////////////////////
// entrance
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.entrance {
  margin-bottom: m.clamp_sizeWidth(40, 50, 768, 1280);
  @include m.mq-down(md) {
    margin-bottom: m.clamp_sizeWidth(38, 48, 375, 768);
  }
}