@charset "utf-8";

///////////////////////////////////////////////////////////
// btn
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.btn {
  position: relative;
  display: inline-block;
  font-size: m.clamp_sizeWidth(13, 15, 768, 1280);
  font-weight: 600;
  text-align: center;
  width: m.clamp_sizeWidth(240, 280, 768, 1280);
  max-width: 100%;
  padding: .75em 1em;
  box-sizing: border-box;
  border-radius: 25px;
  border: 2px solid transparent;
  background-color: v.$clr_blue;
  transition: all .3s a.$ease;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(12, 23, 375, 768);
    width: m.clamp_sizeWidth(195, 390, 375, 768);
    border-radius: m.clamp_sizeWidth(25, 50, 375, 768);
  }
  @include m.linkColor(#fff);
  @include m.hover {
    color: v.$clr_blue;
    border: 2px solid v.$clr_blue;
    background-color: #fff;
  }

  &--large {
    width: 100%;
    max-width: m.clamp_sizeWidth(310, 350, 768, 1280);
    @include m.mq-down(md) {
      max-width: m.clamp_sizeWidth(280, 600, 375, 768);
    }
  }

  &--next {
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 1.3em;
      transform: translateY(-50%);
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 6px solid #fff;
      border-right: 0;
      @include m.mq-down(md) {
        border-top: m.clamp_sizeWidth(5, 10, 375, 768) solid transparent;
        border-bottom: m.clamp_sizeWidth(5, 10, 375, 768) solid transparent;
        border-left: m.clamp_sizeWidth(6, 12, 375, 768) solid #fff;
      }
    }
    @include m.hover {
      &::after {
        border-left: 6px solid v.$clr_blue;
        @include m.mq-down(md) {
          border-left: m.clamp_sizeWidth(6, 12, 375, 768) solid v.$clr_blue;
        }
      }
    }
  }

  &--prev {
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 1.3em;
      transform: translateY(-50%);
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 6px solid #fff;
      border-left: 0;
      @include m.mq-down(md) {
        border-top: m.clamp_sizeWidth(5, 10, 375, 768) solid transparent;
        border-bottom: m.clamp_sizeWidth(5, 10, 375, 768) solid transparent;
        border-right: m.clamp_sizeWidth(6, 12, 375, 768) solid #fff;
      }
    } 
    
    @include m.hover {
      &::before {
        border-right: 6px solid v.$clr_blue;
        @include m.mq-down(md) {
          border-right: m.clamp_sizeWidth(6, 12, 375, 768) solid v.$clr_blue;
        }
      }
    }
  }

  &--detail {
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 1.3em;
      transform: translateY(-50%);
      display: block;
      content: "\f35d";
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
      color: #fff;
    }
    @include m.hover {
      &::after {
        color: v.$clr_blue;
      }
    }
  }

  &--file {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    &::before {
      display: block;
      content: "\f1c1";
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
      color: #fff;
      margin-right: .4em;
    }
    @include m.hover {
      &::before {
        color: v.$clr_blue;
      }
    }
  }

  &--download {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 auto;
    width: m.clamp_sizeWidth(310, 350, 768, 1280);
    @include m.mq-down(md) {
      width: m.clamp_sizeWidth(280, 600, 375, 768);
    }

    &::before {
      display: block;
      content: "\f56d";
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
      color: #fff;
      margin-right: .4em;
    }

    @include m.hover {
      &::before {
        color: v.$clr_blue;
      }
    }
  }
}
