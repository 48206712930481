@charset "utf-8";

///////////////////////////////////////////////////////////
// about
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.about__college_unit {
  display: grid;
  gap: 0 3rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 3rem;
  @include m.mq-down(md) {
    display: block;
  }
}

.about__college_thumb {
  img {
    width: 100%;
  }
}

.about__college_title {
  font-size: m.clamp_sizeWidth(18, 22, 768, 1280);
  font-weight: 600;
  line-height: 1.64;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(18, 36, 375, 768);
    margin-top: .5em;
  }
}

.about__college_text {
  font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
  line-height: 2.4;
  margin-top: 0.75em;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(12, 24, 375, 768);
  }
}

.about__movie_tabs {
  display: flex;
  border-bottom: 1px solid v.$clr_blue;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 3rem;
  @include m.mq-down(md) {
    margin-bottom: m.clamp_sizeWidth(15, 30, 375, 768);
  }

  &::-webkit-scrollbar {
    display:none;
  }
}

.about__movie_tab {
  font-size: 1.2rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
  min-width: 27.2rem;
  padding: 1.3em 1em;
  background-color: v.$clr_gray;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  @include m.linkColor(#fff);
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(12, 22, 375, 768);
    padding: .45em 1em;
    min-width: m.clamp_sizeWidth(122, 222, 375, 768);
  }

  & + & {
    margin-left: .7rem;
  }

  &.is-tab-active {
    background-color: v.$clr_blue;
    pointer-events: none;
  }
}

.about__movie_contents {
  display: none;

  &.is-tab-active {
    display: block;
  }
}

.about__data_link {
  display: block;

  img {
    transition: v.$transition_opacity;
  }

  @include m.hover {
    img {
      opacity: v.$opacity;
    }
  }
}