@charset "utf-8";

///////////////////////////////////////////////////////////
// 404
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.error {
  text-align: center;
}
.error__txt {
  font-size: m.clamp_sizeWidth(26, 28, 768, 1280);
  font-weight: 600;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(18, 36, 375, 768);
  }
}
.error__list {
  display: inline-block;
  text-align: center;
  margin: m.clamp_sizeWidth(30, 40, 768, 1280) auto 0;
}
.error__item {
  display: block;
  text-align: left;
  font-size: m.clamp_sizeWidth(16, 18, 768, 1280);
  line-height: 1.6;
  & + & {
    margin-top: .5em;
  }
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(12, 23, 375, 768);
    padding-left: 1.5em;
    text-indent: -1.5em;
  }
}
.error__btn {
  text-align: center;
  margin-top: m.clamp_sizeWidth(80, 100, 768, 1280);
  @include m.mq-down(md) {
    margin-top: m.clamp_sizeWidth(50, 100, 375, 768);
  }
}

