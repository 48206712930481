@charset "utf-8";

///////////////////////////////////////////////////////////
// header
///////////////////////////////////////////////////////////

@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.header {
  position: relative;
  height: 16rem;
  @include m.mq-down(md) {
    height: m.clamp_sizeWidth(88, 158, 375, 768);
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: 15.625%;
    height: inherit;
    background-color: v.$clr_blue;
    clip-path: polygon(0 0, 0% 100%, 100% 0);
    @include m.mq-down(md) {
      width: 20%;
    }
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    content: "";
    display: block;
    width: 15.625%;
    height: inherit;
    background-color: v.$clr_yellow;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    @include m.mq-down(md) {
      width: 20%;
    }
  }
}

.header__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
}

.header__character {
  position: relative;
  width: 14.7rem;
  text-align: right;
  @include m.mq-down(md) {
    width: m.clamp_sizeWidth(83, 147, 375, 768);
  }
}

.header__character_balloon {
  position: absolute;
  top: -8px;
  left: 0;
  cursor: pointer;
  transition: transform .2s a.$easeInOut;
  @include m.mq-down(md) {
    width: m.clamp_sizeWidth(48, 80, 375, 768);
  }

  @include m.hover {
    transform: translateY(-5px);
  }
}

.header__character_image {
  @include m.mq-down(md) {
    width: m.clamp_sizeWidth(36, 72, 375, 768);
  }
}

.header__title {
  line-height: 1;
  @include m.mq-down(md) {
    width: m.clamp_sizeWidth(162, 284, 375, 768);
  }
  
  &--home {
    margin-left: 1rem;
    @include m.mq-down(md) {
      margin-right: m.clamp_sizeWidth(88, 157, 375, 768);
    }
  }
}

.header__title_link {
  display: block;
}

.header__navBtn {
  position: fixed;
  top: m.clamp_sizeWidth(20, 50, 375, 768);
  right: 6.6%;
  display: grid;
  place-items: center;
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 50%;
  background-color: v.$clr_blue;
  z-index: 999;
  @include m.mq-down(md) {
    width: m.clamp_sizeWidth(48, 65, 375, 768);
    height: m.clamp_sizeWidth(48, 65, 375, 768);
    right: 3.9%;
  }

  &.is-open {
    .header__navBtn_line {
      background: #fff;

      &:nth-child(1) {
        transform: translate(-50%,12px) rotate(45deg);
        @include m.mq-down(sm) {
          transform: translate(-50%,10px) rotate(45deg);
        }
      }
      &:nth-child(2) {
        opacity: 0;
        transform: translate(-100%, -50%);
      }
      &:nth-child(3) {
        transform: translate(-50%,-8px) rotate(-45deg);
        @include m.mq-down(sm) {
          transform: translate(-50%,-6px) rotate(-45deg);
        }
      }
    }
  }
}

.header__navBtn_inner {
  position: relative;
  width: 3rem;
  height: 2.2rem;
  display: inline-block;
  @include m.mq-down(sm) {
    width: 2.2rem;
    height: 1.8rem;
  }
}

.header__navBtn_line {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  border-radius: 2px;
  transition: all .4s a.$easeOut;

  &:nth-child(1) {
    top: 0;
  }
  &:nth-child(2) {
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity .2s, transform .4s a.$easeOut;
  }
  &:nth-child(3) {
    bottom: 0;
  }
}

/* ヘッダーメニュー
---------------------------------------------------------- */
/* メニュー展開時 背景固定 */
body.is-open {
  height: 100%;
  overflow: hidden;
}

.header__nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 40vw;
  max-width: 50rem;
  height: 100vh;
  height: 100dvh;
  background-color: v.$clr_blue;
  transform: translateX(100%);
  transition: transform .5s a.$easeInOutCubic;
  pointer-events: none;
  overflow: scroll;
  z-index: 100;
  @include m.mq-down(lg) {
    width: 50vw;
  }
  @include m.mq-down(md) {
    width: 70vw;
  }
  @include m.mq-down(sm) {
    width: 100vw;
    max-width: 100%;
  }

  &.is-open {
    transform: translateX(0);
    pointer-events: auto;
  }
}

.header__navList {
  padding: 12rem 7.2rem;
  @include m.mq-down(md) {
    padding: m.clamp_sizeWidth(80, 120, 375, 768) 7.2rem;
  }
}

.header__navItem {
  color: #fff;
  opacity: 0;
  visibility: hidden;

  & + & {
    margin-top: 2.8rem;
  }

  &--outlook {
    margin-top: 6.8rem !important;
    @include m.mq-down(md) {
      margin-top: m.clamp_sizeWidth(34, 68, 375, 768);
    }
  }
}

.header__navLink {
  position: relative;
  color: #fff;
  display: inline-block;
  padding-left: 1.2em;
  transition: color .2s a.$easeOut;
  @include m.linkColor(#fff);
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(14, 23, 375, 768);
    padding-left: 1em;
  }

  &::before {
    content: "\f0da";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-44%);
    font-size: 1.8rem;
    font-family: "Font Awesome 6 Free";
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(14, 23, 375, 768);
    }
  }

  &.outlook {
    padding-left: 0;
    padding-right: 2em;
    @include m.mq-down(md) {
      padding-right: 1.4em;
    }

    &::before {
      display: none;
    }
    
    .outlookIcon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-48%);

      &::before {
        content: "\f08e";
        font-size: 1.6rem;
        font-family: "Font Awesome 6 Free";
        @include m.mq-down(md) {
          font-size: m.clamp_sizeWidth(14, 22, 375, 768);
        }
      }
    }
  }
}

.header__navLink_txt {
  position: relative;
  display: inline-block;
  font-size: 1.8rem;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(14, 23, 375, 768);
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: .2rem;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: opacity .2s a.$easeOut;
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  @include m.hover {
    &::after {
      transform: scaleX(1);
      transform-origin: 0 0;
    }
  }
}

.header__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  transform: translateX(100%);
  background-color: rgba(225,225,225, 0.8);
  z-index: 50;
  transition: transform .5s a.$easeInOutCubic;
  @include m.mq-down(sm) {
    display: none;
  }

  &.is-open {
    transform: translateX(0);
  }
}

/* モーダル
---------------------------------------------------------- */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  display: none;
  z-index: 999;

  &.is-modalOpen {
    display: block;
  }

  /* animation
  ---------------------------------------------------------- */
  &[aria-hidden="false"] .modal__overlay,
  &[aria-hidden="false"] .modal__content {
    animation: modalShow 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
  }
  
  &[aria-hidden="true"] .modal__overlay,
  &[aria-hidden="true"] .modal__content {
    animation: modalClose 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
  }
  
  @keyframes modalShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes modalClose {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

.modal__overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(51,51,51,0.6);
}

.modal__wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(87vw, 92rem);
  max-height: 90%;
}

.modal__content {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid v.$clr_blue;
}

.modal__inner {
  width: 100%;
  max-height: 90vh;
  max-height: 90dvh;
  padding: 6rem m.clamp_sizeWidth(35, 95, 768, 1280);
  overflow-y: auto;
  @include m.mq-down(md) {
    max-height: 80vh;
    max-height: 80dvh;
    padding: m.clamp_sizeWidth(30, 60, 375, 768) 8%;
  }
}

.modal__close {
  position: absolute;
  width: 6.5rem;
  height: 6.5rem;
  top: -2.5rem; 
  right: -2.5rem;
  border-radius: 50%;
  background-color: v.$clr_blue;
  border: 2px solid transparent;
  transition: border .4s a.$ease, background-color .4s a.$ease;
  @include m.mq-down(md) {
    width: m.clamp_sizeWidth(45, 65, 375, 768);
    height: m.clamp_sizeWidth(45, 65, 375, 768);
    top: -3.5%;
    right: -3.5%;
  }
  
  &::before {
    content: "\f00d";
    font-family: "Font Awesome 6 Free";
    color: #fff;
    font-size: 3rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: color .4s a.$ease;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(20, 30, 375, 768);
    }
  }

  @include m.hover {
    border: 2px solid v.$clr_blue;
    background-color: #fff;
    &::before {
      color: v.$clr_blue;
    }
  }
}

.modal__character_subTitle {
  font-size: m.clamp_sizeWidth(13, 15, 768, 1280);
  font-weight: bold;
  text-align: center;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(12, 22, 375, 768);
  }
}

.modal__character_image {
  text-align: center;
  margin-top: 4rem;
  @include m.mq-down(md) {
    margin-top: m.clamp_sizeWidth(30, 40, 375, 768);
  }
}

.modal__character_inner {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  max-width: 73rem;
  @include m.mq-down(md) {
    flex-direction: column;
    margin-top: m.clamp_sizeWidth(20, 30, 375, 768);
  }
}

.modal__character_info {
  width: calc((100% - 4.1%) / 2);
  @include m.mq-down(md) {
    width: 100%;
  }

  &:nth-of-type(1) {
    .modal__character_title {
      width: 35%;
      @include m.mq-down(md) {
        width: 40%;
      }
    }
    .modal__character_text {
      width: 65%;
      @include m.mq-down(md) {
        width: 60%;
      }
    }
  }

  &:nth-of-type(2) {
    .modal__character_title {
      width: 54%;
      @include m.mq-down(md) {
        width: 40%;
      }
    }
    .modal__character_text { 
      width: 46%;
      @include m.mq-down(md) {
        width: 60%;
      }
    }
  }
}

.modal__character_wrap {
  display: flex;
  justify-content: space-between;
  padding: 1.9rem 0 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.modal__character_title {
  font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
  font-weight: 600;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(12, 24, 375, 768);
  }
}

.modal__character_text {
  font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(12, 24, 375, 768);
  }
}