@charset "utf-8";

///////////////////////////////////////////////////////////
// linkCards
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.linkCards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  @include m.mq-down(md) {
    grid-template-columns: repeat(1, 1fr);
    gap: 5rem;
  }
}

.linkCard {
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.linkCard__link {
  display: block;
  overflow: hidden;
  border-radius: 10px;
  @include m.hover {
    .linkCard__image {
      transform: v.$transform_scale;
    }
  }
}

.linkCard__thumb {
  overflow: hidden;
  margin: 0;
}

.linkCard__image {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 540 / 308;
  border-radius: 10px 10px 0 0;
  transition: v.$transition_scale;
}

.linkCard__body {
  padding: 2rem;
  background-color: #fff;
  @include m.mq-down(md) {
    padding: m.clamp_sizeWidth(16, 32, 375, 768);
  }
}

.linkCard__title {
  position: relative;
  font-size: m.clamp_sizeWidth(20, 26, 768, 1280);
  font-weight: 600;
  color: v.$clr_black;
  padding-left: .7em;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(16, 32, 375, 768);
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-45%);
    width: .5rem;
    height: 100%;
    background-color: v.$clr_blue;
    border-radius: 2px;
    @include m.mq-down(md) {
      width: m.clamp_sizeWidth(2.5, 5, 375, 768);
    }
  }

  &--outlook {
    display: inline-block;
    padding-right: 1.1em;
    @include m.mq-down(md) {
      padding-right: 1.4em;
    }

    &::after {
      position: absolute;
      top: .2em;
      right: 0;
      content: "\f35d";
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
      font-size: m.clamp_sizeWidth(18, 22, 768, 1280);
      color: v.$clr_blue;
      margin-left: .38em;
      @include m.mq-down(md) {
        font-size: m.clamp_sizeWidth(15, 30, 375, 768);
        top: .1em;
      }
    }
  }
}

.linkCard__text {
  font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
  color: v.$clr_black;
  line-height: 2.13;
  margin-top: 1em;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(12, 24, 375, 768);
  }
}

.linkCard__more {
  position: relative;
  font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
  font-family: v.$fnt_en;
  color: v.$clr_black;
  text-align: right;
  padding-right: 1em;
  margin-top: 1em;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(12, 24, 375, 768);
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    content: "\f0da";
    font-weight: 900;
    font-family: "Font Awesome 6 Free";
    font-size: m.clamp_sizeWidth(12, 15, 768, 1280);
    color: v.$clr_blue;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(12, 24, 375, 768);
    }
  }
}