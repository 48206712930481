@charset "utf-8";

///////////////////////////////////////////////////////////
// index
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

// ---------------------------------------------------------
// heading
// ---------------------------------------------------------
.heading {
  font-size: m.clamp_sizeWidth(30, 32, 768, 1280);
  font-family: v.$fnt_en;
  letter-spacing: .04em;
  text-align: center;
  text-transform:uppercase;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(28, 32, 375, 768);
  }
  
  span {
    font-size: inherit;
  }
}

// ---------------------------------------------------------
// fv
// ---------------------------------------------------------
.fv__slider {
  line-height: 0;
  opacity: 0;
  transition: opacity .3s a.$ease;

  &.slick-initialized {
    opacity: 1;
  }

  /* スライド画像が1枚のとき */
  &.is-only {
    .slick-list {
      width: min(92%,110rem);
      padding: 0 !important;
      margin: 0 auto;
    }

    .fv__slide_wrap {
      margin: 0;
      max-height: 55rem;
    }
  }

  /* arrow */
  .slider-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: m.clamp_sizeWidth(60, 80, 768, 1280);
    height: m.clamp_sizeWidth(60, 80, 768, 1280);
    border-radius: 50%;
    border: 2px solid v.$clr_blue;
    background-color: #fff;
    z-index: 10;
    @include m.mq-down(md) {
      width: m.clamp_sizeWidth(35, 55, 375, 768);
      height: m.clamp_sizeWidth(35, 55, 375, 768);
    }
    
    i {
      font-size: m.clamp_sizeWidth(30, 35, 768, 1280);
      color: v.$clr_blue;
      @include m.mq-down(md) {
        font-size: m.clamp_sizeWidth(16, 22, 375, 768);
      }
    }
  }

  .slider-prev {
    left: 2rem;
    @include m.mq-down(md) {
      left: 4.4%;
    }
  }

  .slider-next {
    right: 2rem;
    @include m.mq-down(md) {
      right: 4.4%;
    }
  }

  /* dots */
  .slick-dots {
    bottom: -3.5rem;
    @include m.mq-down(md) {
      bottom: -3rem;
    }
  }
  .slick-dots li {
    width: 1rem;
  }
  .slick-dots li button {
    width: 1rem;
  }
  .slick-dots li button:before {
    font-size: 1rem;
    color: v.$clr_lgray;
    width: 1rem;
    opacity: 1;
  }
  .slick-dots li.slick-active button:before {
    color: v.$clr_blue;
    opacity: 1;
  }
}

.fv__slide_wrap {
  margin: 0 1.6rem;
  @include m.mq-down(md) {
    margin: 0 m.clamp_sizeWidth(7, 15, 375, 768);
  }
}

.fv__slide_link {
  display: block;
  overflow: hidden;
  border-radius: 10px;

  @include m.hover {
    .fv__slide_image {
      transform: v.$transform_scale;
    }
  }
}

.fv__slide_image {
  width: 100%;
  transition: v.$transition_scale;
}

/* スライド画像（リンク無し） */
div.fv__slide_link {
  pointer-events: none;
}

// ---------------------------------------------------------
// contents
// ---------------------------------------------------------
.contents__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  margin-top: 3rem;
  @include m.mq-down(md) {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin-top: m.clamp_sizeWidth(15, 30, 375, 768);
  }
  @include m.mq-down(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.contents__item_link {
  display: block;
  overflow: hidden;

  .contents__item_image {
    transition: v.$transition_scale;
  }

  @include m.hover {
    .contents__item_image {
      transform: v.$transform_scale;
    }
  }
}

.contents__item_image {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 350 / 247;
}

// ---------------------------------------------------------
// news
// ---------------------------------------------------------
.news__btn {
  text-align: center;
  margin-top: m.clamp_sizeWidth(24, 30, 768, 1280);
}

// ---------------------------------------------------------
// appeal
// ---------------------------------------------------------
.appeal__bnrLink {
  display: block;

  img {
    transition: v.$transition_opacity;
  }

  @include m.hover {
    img {
      opacity: v.$opacity;
    }
  }
}