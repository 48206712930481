@charset "utf-8";

///////////////////////////////////////////////////////////
// container
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.movieCards {
  display: grid;
  gap: 3rem;

  &--col2 {
    grid-template-columns: repeat(2, 1fr);
    @include m.mq-down(md) {
      gap: m.clamp_sizeWidth(30, 60, 375, 768);
      grid-template-columns: repeat(1, 1fr);
    }

    .movieCard__wrap {
      aspect-ratio: 540 / 304;
    }
  }

  &--col3 {
    grid-template-columns: repeat(3, 1fr);
    @include m.mq-down(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include m.mq-down(md) {
      gap: m.clamp_sizeWidth(30, 40, 375, 768);
      grid-template-columns: repeat(1, 1fr);
    }

    .movieCard__wrap {
      aspect-ratio: 350 / 197;
    }
  }
}

.movieCard__wrap {
  width: 100%;
}

.movieCard__title {
  font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
  font-weight: 600;
  margin-top: .5em;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(14, 24, 375, 768);
  }
}