@charset "utf-8";

///////////////////////////////////////////////////////////
// 変数定義用SASS
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
// @use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

// ---------------------------------------------------------
// ブレイクポイント
// ---------------------------------------------------------
$breakpoint_up: (
  sm: "screen and (min-width: 576px)",
  md: "screen and (min-width: 768px)",
  lg: "screen and (min-width: 992px)",
  xl: "screen and (min-width: 1200px)",
  xxl: "screen and (min-width: 1400px)"
);
$breakpoint_down: (
  sm: "screen and (max-width: 575.98px)",
  md: "screen and (max-width: 767.98px)",
  lg: "screen and (max-width: 991.98px)",
  xl: "screen and (max-width: 1199.98px)",
  xxl: "screen and (max-width: 1399.98px)"
);

// ---------------------------------------------------------
// レイアウト
// ---------------------------------------------------------
// コンテナ幅
$container_width: ( fluid:100%, md:720px, lg:960px, xl:1140px, xxl:1320px );

// 余白
$gap: 3rem; // 30px

// ---------------------------------------------------------
// hover
// ---------------------------------------------------------
$transform_scale: scale(1.02);
$transition_scale: transform .5s a.$ease;

$opacity: .6;
$transition_opacity: opacity .4s a.$ease;

// ---------------------------------------------------------
// カラー
// ---------------------------------------------------------
// グレー系
$clr_black: #333;
$clr_gray: #c4c4c4;
$clr_lgray: #d1d1d1;

$clr_blue: #1591fc;
$clr_lblue: #e6f3ff;
$clr_skyblue: #8cc8f9;
$clr_lskyblue: #f4faff;
$clr_yellow: #f5cd48;
$clr_pink: #f06a8e;
$clr_dred: #74243e;

// ---------------------------------------------------------
// デフォルトフォント設定
// ---------------------------------------------------------
// フォントサイズ
$fnt_size_default: 1.2rem;

// 太さ
$fnt_weight_default: 400;

// 行間
$fnt_lh_default: 1.6;

// フォントファミリー
@import 'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;600&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Sigmar&display=swap';

$fnt_ja: 'Noto Sans JP', sans-serif;
$fnt_en: 'Sigmar', cursive;
$fnt_gothic: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;