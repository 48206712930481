@charset "utf-8";

///////////////////////////////////////////////////////////
// newsList
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.newsList {
  border-top: 1px solid #e8e8e8;

  &--home {
    border-top: none;
  }
}

.newsList__item {
  position: relative;
  border-bottom: 1px solid #e8e8e8;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid v.$clr_blue;
    border-right: 0;
    @include m.mq-down(md) {
      border-top: m.clamp_sizeWidth(6, 12, 375, 768) solid transparent;
      border-bottom: m.clamp_sizeWidth(6, 12, 375, 768) solid transparent;
      border-left: m.clamp_sizeWidth(7, 15, 375, 768) solid v.$clr_blue;
    }
  }
}

.newsList__item_link {
  position: relative;
  display: flex;
  align-items: center;
  padding: m.clamp_sizeWidth(16, 20, 768, 1280) 0;
  @include m.mq-down(md) {
    flex-direction: column;
    align-items: flex-start;
    padding: m.clamp_sizeWidth(10, 20, 375, 768) m.clamp_sizeWidth(20, 40, 375, 768) m.clamp_sizeWidth(10, 20, 375, 768) 0;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    display: block;
    width: 100%;
    height: 1px;
    background-color: v.$clr_blue;
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
    z-index: 1;
  }

  @include m.hover {
      &::after {
      transform: scaleX(1);
      transform-origin: 0 0;
    }

    .newsList__item_title {
      color: v.$clr_blue;
    }
  }
}

.newsList__item_head {
  display: flex;
  align-items: center;
  @include m.mq-down(md) {
    justify-content: flex-start;
    width: 100%;
  }
}

.newsList__item_date  {
  margin-right: m.clamp_sizeWidth(10, 14, 768, 1280);
  @include m.mq-down(md) {
    margin-right: m.clamp_sizeWidth(5, 10, 375, 768);
  }
  time {
    font-size: 1.2rem;
    font-weight: 600;
    color: v.$clr_black;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(10, 20, 375, 768);
    }
  }
}

.newsList__item_cateList {
  display: flex;
  margin-top: -.8rem;
  margin-left: -.8rem;
  // margin-bottom: 1rem;
  @include m.mq-down(md) {
    margin-top: -.2rem;
    margin-left: -.5rem;
    // margin-bottom: m.clamp_sizeWidth(5, 10, 375, 768);
  }
  li {
    a {
      transition: v.$transition_opacity;
      
      @include m.linkColor(#fff);
      @include m.hover {
        opacity: v.$opacity;
      }
    }
  }
}

.newsList__item_cateItem {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
  min-width: 8rem;
  padding: .1em 1em .13em;
  margin-top: .8rem;
  margin-left: .8rem;
  border-radius: 11px;
  white-space: nowrap;
  box-sizing: border-box;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(10, 20, 375, 768);
    padding: 0.052em 1em;
    min-width: auto;
    margin-top: .2rem;
    margin-left: .5rem;
    border-radius: 17px;
  }

  &--blue {
    background-color: v.$clr_blue;
  }

  &--yellow {
    background-color: v.$clr_yellow;
  }

  &--skyblue {
    background-color: v.$clr_skyblue;
  }

  &--pink {
    background-color: v.$clr_pink;
  }
}

.newsList__item_title {
  font-size: 1.2rem;
  color: v.$clr_black;
  margin-left: m.clamp_sizeWidth(20, 30, 768, 1280);
  transition: color .4s a.$ease;
  @include m.mq-down(md) {
    width: 100%;
    font-size: m.clamp_sizeWidth(13, 23, 375, 768);
    margin-top: m.clamp_sizeWidth(5, 10, 375, 768);
    margin-left: 0;
  }
}