@charset "utf-8";

///////////////////////////////////////////////////////////
// subfv
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.subfv {
  display: grid;
  place-items: center;
  height: m.clamp_sizeWidth(230, 250, 768, 1280);
  background-color: v.$clr_lblue;
  background: url("img/common/img_bg@2x.png");
  background-size: 50%;
  border-radius: 10px;
  margin-bottom: 2rem;
  @include m.mq-down(md) {
    height: m.clamp_sizeWidth(175, 250, 375, 768);
  }
}
.subfv__title {
  text-align: center;

  .en {
    display: inline-block;
    font-size: m.clamp_sizeWidth(28, 32, 768, 1280);
    font-family: v.$fnt_en;
    letter-spacing: .04em;
    text-transform: uppercase;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(18, 32, 375, 768);
    }

    span {
      font-size: inherit;
    }
  }

  .ja {
    display: block;
    font-size: m.clamp_sizeWidth(13, 15, 768, 1280);
    font-weight: bold;
    text-align: center;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(12, 22, 375, 768);
    }
  }
}