@charset "utf-8";

///////////////////////////////////////////////////////////
// page
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

//---------------------------------------------------------
// content
//---------------------------------------------------------
.content.content--page {
  p {
    & + p {
      margin-top: 0;
    }
  }

  figure {
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      aspect-ratio: auto;
      max-width: 100%;
    }
  }

  .wp-block-button {
    &.btn--large {
      a.wp-block-button__link {
        width: 100%;
        max-width: m.clamp_sizeWidth(310, 350, 768, 1280);
        @include m.mq-down(md) {
          max-width: m.clamp_sizeWidth(280, 600, 375, 768);
        }
      }
    }

    &.btn--file {
      &::before {
        display: none;
      }
      a.wp-block-button__link {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        &::before {
          display: block;
          content: "\f1c1";
          font-weight: 900;
          font-family: "Font Awesome 6 Free";
          color: #fff;
          margin-right: .4em;
        }
        @include m.hover {
          &::before {
            color: v.$clr_blue;
          }
        }
      }
    }
  }
}