@charset "utf-8";

///////////////////////////////////////////////////////////
// cards
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  @include m.mq-down(md) {
    grid-template-columns: repeat(1, 1fr);
    gap: 5rem;
  }
}

.card__title {
  font-size: m.clamp_sizeWidth(16, 18, 768, 1280);
  font-weight: 600;
  margin-bottom: .5em;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(16, 32, 375, 768);
  }
}

.card__image {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 350 / 247;
}

.card__text {
  font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
  line-height: 2.13;
  margin-top: 1em;
  @include m.mq-down(md) {
    font-size: m.clamp_sizeWidth(12, 24, 375, 768);
  }
}