@charset "utf-8";

///////////////////////////////////////////////////////////
// wrapper
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.wrapper {
  padding: 6rem 0 10rem;
  @include m.mq-down(md) {
    padding: m.clamp_sizeWidth(38, 48, 375, 768) 0 m.clamp_sizeWidth(60, 100, 375, 768);
  }

  &--bottomFit {
    padding-bottom: 0;
  }
  
  &--home {
    padding: 10rem 0;
    background: url("img/common/img_bg@2x.png");
    background-size: 33%;
    margin-top: -3rem;
    @include m.mq-down(md) {
      background-size: 100%;
      padding: m.clamp_sizeWidth(60, 100, 375, 768) 0;
    }
  }
}