@charset "utf-8";

///////////////////////////////////////////////////////////
// main
///////////////////////////////////////////////////////////

@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.main {
  min-height: calc(100vh - 46rem);
}