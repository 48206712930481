@charset "utf-8";

///////////////////////////////////////////////////////////
// admission
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;

.admission__info_entrance {
  margin-top: 3rem;

  p {
    font-size: m.clamp_sizeWidth(14, 15, 768, 1280);
    line-height: 2.13;
    @include m.mq-down(md) {
      font-size: m.clamp_sizeWidth(12, 24, 375, 768);
    }
  }

  a {
    @include m.hover {
      text-decoration: underline;
    }
  }
}

.admission__info_content {
  margin-top: 5rem;
  @include m.mq-down(md) {
    margin-top: m.clamp_sizeWidth(50, 100, 375, 768);
  }
}